import React, { useState } from "react";
import {
  createStyles,
  ITheme,
  makeStyles,
  useMediaQuery,
} from "@chainsafe/common-theme";
import { Grid, Typography } from "@chainsafe/common-components";
import nav from "./Images/nav.svg";
import close from "./Images/close.svg";
import CustomMenu from "./CustomMenu";
import brand from "./Images/brand.png";

const useStyles = makeStyles(
  ({ breakpoints, palette, zIndex, constants }: ITheme) => {
    return createStyles({
      container: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "fixed",
        zIndex: zIndex?.layer4,
        background: "#121212",
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        [breakpoints.down("1170px")]: {
          justifyContent: "flex-end",
        },
        [breakpoints.down("sm")]: {
          padding: "none",
        },
      },
      linksMenu: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      logo: {
        height: constants.generalUnit * 3,
        margin: constants.generalUnit,
      },
      link: {
        textDecoration: "none",
        textTransform: "uppercase",
        letterSpacing: "1px",
        fontSize: "16px",
        cursor: "pointer",
        padding: "1rem",
        color: palette.common.white.main,
        transition: "all .2s ease-out",
        "&:hover": {
          transform: "translateY(-2px)",
        },
        "&:first-of-type": {
          marginRight: constants.generalUnit * 2,
        },
        "& > span": {
          color: "red",
          fontSize: "12px",
          position: "absolute",
          marginLeft: constants.generalUnit / 2,
        },
      },
      navlink: {
        color: palette.common.white.main,
        textDecoration: "none",
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "13px",
        letterSpacing: ".7px",
        marginRight: constants.generalUnit / 2,
        "&:hover": {
          color: palette.primary.main,
          transition: "ease-in 0.2s",
        },
        [breakpoints.up("sm")]: {
          marginRight: `${constants.generalUnit * 2}px`,
        },
      },
      menuToggle: {
        [breakpoints.down(1170)]: {
          display: "block",
          cursor: "pointer",
        },
        display: "none",
        width: "30px",
        marginTop: "13px",
        zIndex: 400000,
        height: "30px",
        transition: "transform 0.4s ease-in",
      },
      wrapper: {
        background: palette.additional["gray"][10],
        position: "absolute",
        left: 0,
        top: "70px",
        width: "100%",
        height: "54vh",
        zIndex: 400000,
      },
      mobileLinkContainer: {
        zIndex: 1000,
        display: "flex",
        background: "#292929",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingLeft: "15px",
        paddingRight: constants.generalUnit * 3,
      },
      mobileLinkWrapper: {
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      mobileLink: {
        textDecoration: "none",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
        color: palette.common.white.main,
        transition: "0.1s ease-in-out",
        "&:hover": {
          color: palette.primary.main,
        },
        "&:active": {
          color: palette.primary.main,
        },
      },
      flex: {
        display: "flex",
        alignItems: "center",
      },
      title: {
        fontFamily: "DM Sans",
        fontWeight: 800,
        fontSize: "13px",
        letterSpacing: ".7px",
        marginRight: constants.generalUnit / 2,
      },
      launch:{
        color: "#D46B08",
        "&.svg":{
          color: "#D46B08"
        }
      },
      resources: {
        color: palette.common.white.main,
        "&.svg":{
          color: palette.common.white.main
        }
      },
      item: {
        "& > span": {
          fontSize: "16px",
          fontWeight: 600,
        },
      },
      button: {
        background:
          "linear-gradient(273deg, rgba(246,174,64,1) 0%, rgba(243,39,44,1) 100%)",
        borderRadius: "12px",
        margin: "0 .5rem",
        textTransform: "none",
        fontSize: "16px",
        letterSpacing: "0px",
        padding: constants.generalUnit * 1.5,
        transition: "all ease-in-out 0.4s",
        "&:hover, &:active, &:focus": {
          background:
            "linear-gradient(273deg, rgba(194,137,50,1) 0%, rgba(213,36,40,1) 100%)",
          color: palette.common.white.main,
          transition: "all ease-in-out 0.4s",
        },
      },
      options: {
        width: 140,
        borderRadius: 12,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      },
      spacing:{
        flex:".5 1 0px"
      },
      spacingMenu:{
        flex:".5 1 100px"
      },
      centerLinks:{
        fontFamily: "DM Sans",
        color: "#D6D6D6",
      }
    });
  }
);

const Navbar: React.FC = () => {
  const classes = useStyles();

  const [isNavVisible, setIsNavVisible] = useState(false);

  const isMobile = useMediaQuery("(max-width: 1170px)");

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <nav className={classes.container + ' navbar'}>
      {!isMobile && (
        <Grid item alignItems="center" className={classes.linksMenu + ' menu'}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item alignItems="flex-start" className={classes.spacing}>
              <div className={classes.flex}>
                <a href="/#" target="_self" rel="noopener noreferrer">
                  <img
                    className={classes.logo}
                    src={brand}
                    alt="Paladin logo"
                  />
                </a>
              </div>
            </Grid>
            <Grid item alignItems="center" flexDirection="row" justifyContent="space-evenly">
              <div className={classes.centerLinks}>
                <a
                  href="#quest"
                  rel="noopener noreferrer"
                  className={classes.mobileLink + ' quest'}
                >
                  Quest
                </a>
              </div>
              <div className={classes.centerLinks}>
                <a
                  href="#warlord"
                  rel="noopener noreferrer"
                  className={classes.mobileLink + ' warlord'}
                >
                  Warlord
                </a>
              </div>
              <div className={classes.centerLinks}>
                <a
                  href="#dullahan"
                  rel="noopener noreferrer"
                  className={classes.mobileLink + ' dullahan'}
                >
                  Dullahan
                </a>
              </div>
              <div className={classes.centerLinks}>
                <a
                  href="#boosts"
                  rel="noopener noreferrer"
                  className={classes.mobileLink + ' boosts'}
                >
                  Boosts
                </a>
              </div>
              <div className={classes.centerLinks}>
                <a
                  href="#more"
                  rel="noopener noreferrer"
                  className={classes.mobileLink + ' more'}
                >
                  More
                </a>
              </div>
            </Grid>
            <Grid item flexDirection="row" alignItems="center" justifyContent="flex-end" className={classes.spacingMenu} >
              <CustomMenu
                title="Launch App"
                className={classes.launch + ' navbar-button'}
                classNames={{
                  titleText: classes.title,
                  item: classes.item,
                  options: classes.options,
                }}
                autoclose
                menuItems={[
                  {
                    contents: <Typography>Quest</Typography>,
                    onClick: () =>
                    window.open("https://quest.paladin.vote/", "_blank"),
                  },
                  {
                    contents: <Typography>Warlord</Typography>,
                    onClick: () => window.open("https://warlord.paladin.vote/", "_blank"),

                  },
                  {
                    contents: <Typography>Dullahan</Typography>,
                    onClick: () => window.open("https://dullahan.paladin.vote/", "_blank"),
                  },
                  {
                    contents: <Typography>Boosts</Typography>,
                    onClick: () => window.open("https://boost.warden.vote/boosts", "_blank"),
                  },
                  {
                    contents: <Typography>hPAL</Typography>,
                    onClick: () => window.open("https://app.paladin.vote/#/hpal", "_blank"),
                  },
                ]}
                />
              <CustomMenu
                title="Resources"
                className={classes.resources + ' navbar-button'}
                classNames={{
                  titleText: classes.title,
                  item: classes.item,
                  options: classes.options,
                }}
                autoclose
                menuItems={[
                  {
                    contents: <Typography>Docs</Typography>,
                    onClick: () =>
                    window.open("https://doc.paladin.vote/", "_blank"),
                  },
                  {
                    contents: <Typography>Twitter</Typography>,
                    onClick: () =>
                    window.open("https://twitter.com/Paladin_vote", "_blank"),
                  },
                  {
                    contents: <Typography>Forum</Typography>,
                    onClick: () => window.open("https://gov.paladin.vote/",  "_blank"),
                  },
                  {
                    contents: <Typography>Blog</Typography>,
                    onClick: () => window.open("https://keep.paladin.vote/",  "_blank"),
                  },
                  ]}
                  />
                </Grid>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <>
          <Grid
          container
          justifyContent="flex-start"
            alignItems="center"
            className={classes.linksMenu}
            >
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a href="/#" target="_self" rel="noopener noreferrer">
                  <img className={classes.logo} src={brand} alt="Paladin logo" />
                </a>
              </div>
            </Grid>
            <Grid item alignItems="flex-end">
              <div className={classes.menuToggle} onClick={toggleNav}>
                {isNavVisible ? (
                  <img src={close} alt="menuIcon" />
                ) : (
                  <img src={nav} alt="close Icon" />
                )}
              </div>
            </Grid>
          </Grid>
          {isNavVisible && (
            <Grid item alignItems="center" className={classes.linksMenu}>
              <Grid item alignItems="flex-end">
                <div className={classes.wrapper}>
                  <ul className={classes.mobileLinkContainer}>
                  <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://quest.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Quest
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://warlord.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Warlord
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://dullahan.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Dullahan
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://boost.warden.vote/boosts"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Boosts
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://app.paladin.vote/#/hpal"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        hPal
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://doc.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Docs
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://twitter.com/Paladin_vote"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Twitter
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://gov.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Forum
                      </a>
                    </div>
                    <div className={classes.mobileLinkWrapper}>
                      <a
                        href="https://keep.paladin.vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobileLink}
                      >
                        Blog
                      </a>
                    </div>
                  </ul>
                </div>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </nav>
  );
};

export default Navbar;
