import React from "react";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import brand from "./Images/brand.png";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, constants, breakpoints }: ITheme) => {
  return createStyles({
    root: {
      background: "#141414",
      [breakpoints.down("769")]: {
        flexDirection: "column",
        alignItems: "start",
        paddingLeft: "5%",
        paddingRight: "5%",
      },
    },
    bold: {
      fontWeight: 600,
    },
    gray8: {
      color: palette.additional["gray"][8],
    },
    item: {
      letterSpacing: ".7px",
      marginRight: constants.generalUnit / 2,
      display: "block",
      flex: 0,
      cursor: "pointer",
      padding: "8px",
    },
    copyright: {
      position: "absolute",
      color: palette.additional["gray"][8],
      [breakpoints.down("xl")]: {
        marginLeft: constants.generalUnit * 2,
      },
      [breakpoints.up("xl")]: {
        textAlign: "left",
        fontSize: "1rem",
        color: palette.additional["gray"][7],
      },
    },
    linkList: {
      display: "flex",
      flexDirection: "column",
      marginTop: "50px",
      [breakpoints.down("769")]: {
        marginTop: "40px",
      },
    },
    groupLists: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexGrow: .7,
      [breakpoints.down("769")]: {
        gap: "21vw",
      },
      [breakpoints.down("601")]: {
        gap: "8vw",
      },
      [breakpoints.down(426)]:{
        flexDirection: "column",
        gap: "0px",
      }
    }, 
    logo: {
      height: constants.generalUnit * 3,
      margin: constants.generalUnit,
    },
    subText: {
      fontFamily: "DM Sans",
      fontSize: "20px",
      color: "#A3A3A3",
      paddingLeft:"5px"
    },
  });
});

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root + ' footer'}>
      <div className='footer-content'>
        <div className={clsx(classes.linkList)}>
          <a href="/#" target="_self" rel="noopener noreferrer">
            <img
              className={classes.logo}
              src={brand}
              alt="Paladin logo"
              />
          </a>
          <p className='subtitle-secondary'>
            DeFi governance unlocked
          </p>
        </div>
        <div className={classes.groupLists + ' footer-links'}>
          <div className={classes.linkList}>
            <div className={classes.item}>
              <a href="https://quest.paladin.vote/" target="__blank">
                Quest
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://warlord.paladin.vote/" target="__blank">
                Warlord
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://dullahan.paladin.vote/" target="__blank">
                Dullahan
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://boost.warden.vote/boosts" target="__blank">
                Boosts
              </a>
            </div>
            <div  className={classes.item}>
              <a href="https://app.paladin.vote/#/hpal" target="__blank">
                hPal
              </a>
            </div>
          </div>
          <div className={classes.linkList}>
            <div className={classes.item}>
              <a href="https://github.com/PaladinFinance" target="__blank">
                GitHub
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://twitter.com/Paladin_vote" target="__blank">
                Twitter
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://discord.gg/gxda4HgYXy" target="__blank">
                Discord
              </a>
            </div>
            <div  className={classes.item}>
              <a href="https://gov.paladin.vote/" target="__blank">
                Forum
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://snapshot.org/#/palvote.eth" target="__blank">
                Snapshot
              </a>
            </div>
          </div>
          <div className={classes.linkList}>
            <div  className={classes.item}>
              <a href="https://doc.paladin.vote/" target="__blank">
                Documentation
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://keep.paladin.vote/" target="__blank">
                Keep Blog
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://weeklygauge.substack.com/" target="__blank">
                Weekly Gauge Newsletter
              </a>
            </div>
            <div className={classes.item}>
              <a href="https://drive.google.com/drive/u/2/folders/1v-uJXHRN6jGEYynZFJOBhTOZxMyIbVUs" target="__blank">
                Brand kit
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
